import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState, useStateIsDefined, useWindowSizeTracker } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { ContactSuccessNotification } from '../../components/contact/ContactSuccessNotification.js';
import { useOnNavigation } from '../../hooks/useOnNavigation.js';
import { UnexpectedError } from '../../UnexpectedError.js';
import { CarDetailsNavBar } from './CarDetailsNavBar.js';

export function CarDetailsLayout({ $gs }: MarketplaceAppProps): JSX.Element {
  // React to navigation events
  useOnNavigation($gs);
  // Track the window size
  useWindowSizeTracker($gs);
  const theme = useGetState($gs.$bulmaTheme);
  const carDetails = useGetState($gs.$details);
  const hasError = useStateIsDefined($gs.$error);

  useEffect(() => {
    // We need to set the theme in the document as we don't have the theme selector in the navbar
    document.documentElement.className = `theme-${theme}`;
  }, [theme]);

  return (
    <>
      <ContactSuccessNotification $gs={$gs} />
      {isTruthy(carDetails) && <CarDetailsNavBar $gs={$gs} $={$gs.$details.asDefined()} />}
      {!hasError ? <Outlet /> : <UnexpectedError $gs={$gs} />}
    </>
  );
}
