import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetState, useScreenIsBulmaMobile, useSetCallback } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import './ContactSuccessNotification.scss';

type ContactSuccessNotificationProps = MarketplaceAppProps;

export function ContactSuccessNotification({ $gs }: ContactSuccessNotificationProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const isNotificationVisible = useGetState($gs.$contactModal.$success);
  const closeSuccessNotification = useSetCallback($gs.$contactModal.$success, false);

  return (
    <>
      {isNotificationVisible && (
        <div
          className={`notification contact-success-notification p-2 pr-6 has-blue-background${isMobile ? ' mobile is-size-6-and-half' : ''}`}
        >
          <button
            type="button"
            className="delete"
            onClick={closeSuccessNotification}
            aria-label={t('contactForm.closeSuccessNotificationAriaLabel')}
          />
          <p className="has-text-white has-text-weight-bold">{t('contactForm.success')}</p>
        </div>
      )}
    </>
  );
}
