import type { JSX } from 'react';
import React from 'react';
import './NumberTag.scss';

interface NumberTagProps {
  readonly label: number;
  readonly isMobile?: boolean;
  readonly highlighted?: boolean;
}

export function NumberTag({ label, isMobile, highlighted }: NumberTagProps): JSX.Element {
  return (
    <div
      className={`number-tag has-border-blue is-flex is-align-items-center is-justify-content-center ${highlighted ? 'has-blue-background' : 'has-background-light'}${isMobile ? ' mobile' : ''}`}
    >
      <span
        className={`tag p-0 is-medium is-size-6${isMobile ? '-and-half' : ''} ${highlighted ? 'has-text-white' : 'has-text-black'} has-text-weight-bold has-background-transparent`}
      >
        {label}
      </span>
    </div>
  );
}
